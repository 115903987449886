<style>
#improoving-widget .gift-cards .list-group-item h1{
	font-size: 16px;
}
#improoving-widget .gift-cards .list-group-item em{
	font-size: 12px;
}
</style>

<template>
	<div class="gift-cards">
		<loading v-if="!loaded"></loading>
		<div v-else-if="availableGiftCards.length === 0" class="w-100 text-center">
			<h5>{{ $trans('no_giftCard') }}</h5>
		</div>
		<template v-else>
			<Box v-for="giftCard in availableGiftCards"
				:key="['giftCard', giftCard.id].join('.')"
				:giftCard="giftCard"
				@selectGiftCard="selectGiftCard(giftCard)"></Box>

			<!--
			<li v-for="giftCard in availableGiftCards"
				:key="['giftCard', giftCard.id].join('.')"
				class="list-group-item p-1"
				@click="showInfo(giftCard)">
				<div class="row">
					<div class="col-4">
						<img class="img-fluid rounded" :src="giftCard.image.url" :alt="giftCard.image.alt" />
					</div>
					<div class="col">
						<h1 class="mb-0">{{ giftCard.name }}</h1>
					</div>
				</div>
				<div v-if="giftCard.show_info" class="w-100">
					<p>{{ $localizedAttribute(giftCard, 'description') }}</p>

					<div class="d-flex w-100 justify-content-end">
						<button type="button" class="btn btn-improoving btn-sm" @click="selectGiftCard(giftCard)">
							{{ $trans('messages.select') }}
						</button>
					</div>
				</div>
			</li>
			-->
		</template>
	</div>
</template>

<script>
import Loading from "./Loading";
import Box from "./_partials/Box";

export default {
	name: 'GiftCards',
	components: {Box, Loading},
	props: {},
	inject: ['schoolId'],
	data(){
		return {
			loaded: false,
			giftCards: {},
		}
	},
	created(){
		document.addEventListener(['show', 'giftCard'].join('-'), (e) => {
			if(typeof this.giftCards[e.detail.productId] !== 'undefined')
				this.selectGiftCard(this.giftCards[e.detail.productId])
			else{
				// alert(this.$trans('productNotFound'))
			}
		});

		this.$requestGET(['school-database', this.schoolId, 'giftcards'].join('/'))
		.then(r => {
			this.giftCards = r.data
			this.loaded = true
			document.dispatchEvent(new CustomEvent('loadedImproovingProducts-giftCard'))
		})
	},
	computed: {
		availableGiftCards(){
			return Object.values(this.giftCards).reduce((acc, giftCard) => {
				if(
					giftCard.sellable_online && parseFloat(giftCard.price) > 0
					&& (giftCard.start_date === null || this.$momentItem(giftCard.start_date).isSameOrBefore(this.$momentItem()))
					&& (giftCard.end_date === null || this.$momentItem(giftCard.end_date).isSameOrAfter(this.$momentItem()))
					&& (giftCard.expiration_date === null || this.$momentItem(giftCard.expiration_date).isSameOrAfter(this.$momentItem()))
				)
					acc.push(giftCard)

				return acc;
			}, []);
		}
	},
	methods:{
		selectSection(){
			this.$emit('selectSection', 'giftCard')
		},
		showInfo(giftCard){
			if(typeof giftCard.show_info === 'undefined')
				giftCard.show_info = true;
			else
				delete giftCard.show_info;

			Object.values(this.giftCards).forEach(db_giftCard => {
				if(db_giftCard.id !== giftCard.id)
					delete db_giftCard.show_info;
			});
		},
		selectGiftCard(giftCard){
			this.$emit('selectGiftCard', giftCard)
		}
	}
}
</script>

