import { createApp } from 'vue'
import SideWidget from './SideWidget.vue'
import axios from 'axios'

import UserManagement from './plugins/user_management'
import CartPlugin from './plugins/cart'
import DatePlugin from './plugins/date_manipolation'
import FormPlugin from './plugins/form_functions'
import RequestsPlugin from './plugins/downloader'
import StoragePlugin from './plugins/storage'
import TranslationPlugin from './plugins/translation'


export default class ImproovingWidget {
	constructor(BearerToken, SchoolID, Options = null, TestEnvironment = false) {
		this.SchoolID = SchoolID
		this.TestEnvironment = TestEnvironment
		this.setBearerToken(BearerToken)
		this.initializeBox(Options)
		this.initializeListeners()
	}

	open = () => {
		this.widgetBox.classList.add('widget-box-shown')
	}
	openSection = (section, categoryId = null, filterName = null) => {
		this.open();
		document.dispatchEvent(new CustomEvent(['show', 'section'].join('-'), {detail: {section: section.trim(), category_id: categoryId !== null ? categoryId.toString().trim() : categoryId, filter_name: filterName !== null ? filterName.toString().toLowerCase().trim() : filterName}}));
	}
	openProduct = (productType, productId) => {
		this.open();
		document.dispatchEvent(new CustomEvent(['show', 'product'].join('-'), {detail: {productType: productType.toString().trim(), productId: productId.toString().trim()}}));
	}
	close = () => {
		this.widgetBox.classList.remove('widget-box-shown')
	}

	setBearerToken = (token) => {
		this.BearerToken = token
		this.initializeAxios()
	}

	initializeAxios = () => {
		axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
		axios.defaults.headers.common["BearerToken"] = this.BearerToken
		axios.defaults.headers.common["Authorization"] = "Bearer " + this.BearerToken
		axios.defaults.headers.common["Accept"] = "application/json"
	}

	initializeBox = (Options) => {
		this.widgetBox = document.createElement('div')
		this.widgetBox.id = 'improoving-widget'

		document.body.appendChild(this.widgetBox)

		this.initializeVue(Options)
	}

	initializeVue = (Options) => {
		let allowedSections = [];
		['packet', 'event', 'moment', 'lesson', 'teacher', 'giftCard'].forEach(section => {
			if(Options === null || typeof Options.sections === 'undefined' || Options.sections === null || typeof Options.sections[section] === 'undefined' || Options.sections[section] !== false)
				allowedSections.push(section);
		});

		// TODO: aggiungere il parametro allowedCategories e non farlo andare in conflitto con blockedCategorie
		let widgetOptions = {
			schoolId: this.SchoolID,
			allowedSections: allowedSections,
			allowTeacherSelection: Options !== null && typeof Options.allowTeacherSelection !== 'undefined' ? Options.allowTeacherSelection : true,
			blockedCategories: Options !== null && typeof Options.blockedCategories !== 'undefined' && Options.blockedCategories instanceof Array ? Options.blockedCategories : [],
			offsetFromDate: Options !== null && typeof Options.offsetFromDate !== 'undefined' ? Options.offsetFromDate : 1,
			offsetToDate: Options !== null && typeof Options.offsetToDate !== 'undefined' ? Options.offsetToDate : null,
			allowLessonWithoutAvailability: Options !== null && typeof Options.allowLessonWithoutAvailability !== 'undefined' ? Options.allowLessonWithoutAvailability : true,
			showPriceOfIncludedServices: Options !== null && typeof Options.showPriceOfIncludedServices !== 'undefined' ? Options.showPriceOfIncludedServices : false
		}

		const VueApp = createApp(SideWidget, widgetOptions)

		VueApp.config.unwrapInjectedRef = true

		// They are loaded in order
		VueApp.use(StoragePlugin)
		VueApp.use(DatePlugin)
		VueApp.use(RequestsPlugin, this.SchoolID, this.TestEnvironment)
		VueApp.use(TranslationPlugin, 'it')
		VueApp.use(UserManagement)
		VueApp.use(CartPlugin, {
			depositPercentage: Options !== null && typeof Options.depositPercentage !== 'undefined' ? Options.depositPercentage : 1,
			allowAdvancePayment: Options !== null && typeof Options.allowAdvancePayment !== 'undefined' ? Options.allowAdvancePayment : false
		})
		VueApp.use(FormPlugin)

		VueApp.mount('#improoving-widget')
	}

	initializeListeners = () => {
		let _this = this;
		let openers = [].slice.call(document.querySelectorAll('.openImproovingWidget, .open-improoving-widget'));
		let closers = [].slice.call(document.querySelectorAll('.closeImproovingWidget, .close-improoving-widget'));

		openers.forEach(opener => {
			opener.addEventListener('click', event => {
				let section= event.target.dataset.section || null,
					categoryId= event.target.dataset.category || null,
					filterName= event.target.dataset.filtername || null,
					productType= event.target.dataset.type || null,
					productId = event.target.dataset.product || null;

				if(productId !== null && (productType !== null || section !== null))
					_this.openProduct(productType || section, productId);
				else if(section !== null && ["event", "giftCard"].includes(section))
					_this.openSection(section, null, filterName);
				else if(section !== null && categoryId !== null)
					_this.openSection(section, categoryId, filterName);
				else
					_this.open();
			});
		})
		closers.forEach(closer => {
			closer.addEventListener('click', () => {
				_this.close();
			});
		})

		// WARNING: let installations override if multiple widgets become supported
		document.addEventListener('openImproovingWidget', () => this.open());
		document.addEventListener('closeImproovingWidget', () => this.close());
		document.addEventListener('openImproovingSection', (e) => this.openSection(e.detail.section, e.detail.categoryId || null, e.detail.filterName || null));
		document.addEventListener('openImproovingProduct', (e) => this.openProduct(e.detail.productType, e.detail.productId));

	}
}

window.ImproovingWidget = ImproovingWidget

window.onload = () => {
	if(process.env.NODE_ENV === 'development'){
		console.warn("Modalità sviluppo abilitata");
		let school_id = 34,
			options = {
				// depositPercentage: 0.4,
				allowedSections: ["lesson"],
				packetText: {
					it: "",
					en: ""
				}
			};

		new ImproovingWidget("eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIzIiwianRpIjoiZWFkNWMzNzQ2MGI3YmFmYzc3YTVjOGZmOTU2NzdlNjJiMTY3NjYwZDk2ZjUzNGZkNGI2MmFmOTBmZGM1Y2U3NDcwNjg5ODQ3MWE4NWIwOTQiLCJpYXQiOjE3MDI5MTIzMDksIm5iZiI6MTcwMjkxMjMwOSwiZXhwIjoxNzM0NTM0NzA5LCJzdWIiOiIxIiwic2NvcGVzIjpbXX0.XSz4oqiAu_pz06vEXQKTi2yDtJlvjoaBuip-0LVXIwIVgeXEYQVvl8Z5N1sUqTX9shLiQ2s_aq2OazddV12R7X-7FhZhNcSIASsXbHi3IoE3Ys23Xw49BoFMuSaIlD2SR9AXuqIPpYi1fjCTB6DGJGQOo2_3lEnnFKzI_hsBL2YpBpBkAyTcfpAjPd43HG5lpI8kqPw_1T2VUxuVQ6IHA0sXH5sIkucKPxd0n9HFiR3-bSfKH7bKI-4j1qMRUjCLKKMa3y82EpEjwR2FtPsFYcu3EvV21pNLSlqKwkqjlz0VqmwmFQozTltBZXZxFEYA38-89xsSNmfoEx-LBiFusOsg3fWEJLMS7vyroz6O3G2DvCRmzXJP3SE-iWF7uwMWAY0w9cObEZUt5gW8DebOEHOfWFQtOc2VuHUD9Ve-APm1G3ACCPt9cjlGAfh5pd0x4k7coKUkFhSaQ8jLFgm_4RjGhwhuRyCEmuO50YmR70KTcTRpkOLdbPoNtbQCiorcDLNxRL-ypxPIAoU0Ki85YwJs_ZOoroR7oQ12OEA65Yuxv-xr33FpzSlD8ikWJOGqy9_PfQ3RCFaTBFoLADTO__EruwdDBchl37JJUy_wcEN0jBe3ic3IqMZaafaA-Mw9f0d1t5yBzcgJ4hZK6F8vAy2JcTqDNhtPPy8h9BvuYCU", school_id, options, true);

		document.dispatchEvent(new CustomEvent('openImproovingWidget'));
		// document.dispatchEvent(new CustomEvent('openImproovingSection', {detail:{section: "packet", categoryId: 1}}));
		// document.dispatchEvent(new CustomEvent('openImproovingSection', {detail:{section: "event", filterName: "13"}}));
		/// document.dispatchEvent(new CustomEvent('openImproovingSection', {detail:{section: "packet", categoryId: 1, filterName: "finale"}}));
		// document.dispatchEvent(new CustomEvent('openImproovingSection', {detail:{section: "lesson", categoryId: 1}}));
		// document.dispatchEvent(new CustomEvent('openImproovingProduct', {detail:{productType: "packet", productId: 1619}}));
		// document.dispatchEvent(new CustomEvent('openImproovingProduct', {detail:{productType: "giftCard", productId: 3}}));
		// Mettere un filtro sui corsi disponibili direttamente nell'evento e lasciare selezionabili le date

		// TODO: togliere l'apertura delle prime date
	}
};